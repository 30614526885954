import Vue from 'vue'
import Router from 'vue-router'
import Login from './components/login.vue'
import timesheet from './components/timesheet.vue'
import profile from "../../vuecommon/profile.vue";
import register from './components/register.vue'
import dash from './components/dash.vue'
import info from './components/info.vue'
import messages from './components/messages.vue'
import changepassword from './components/changepassword.vue'
import holiday from './components/holiday.vue'
import disease from './components/disease.vue'
import filterusers from './components/filterusers.vue'
import users from './components/users.vue'
import editUser from './components/editUser.vue'
import changepasswordadmin from './components/changepasswordadmin.vue'
import settings from './components/settings.vue'
import changepasswordinitial from './components/changepasswordinitial.vue'
import reservate from './components/reservate.vue'
import firma from './components/firma.vue'
import sendMessage from './components/sendMessage.vue'
import filterResources from './components/filterResources.vue'
import resources from './components/resources.vue'
import messaggioBase from './components/messaggioBase.vue'
import company from './components/company.vue'
import editCompany from './components/editCompany.vue'
import companyAgila from './components/companyAgila.vue'
import editCompanyAgila from './components/editCompanyAgila.vue'
import importResult from './components/importResult.vue'
import filterReferenti from './components/filterReferenti.vue'
import referenti from './components/referenti.vue'
import editReferente from './components/editReferente.vue'
import copyReferente from './components/copyReferente.vue'
import logoFirma from './components/logoFirma.vue'
import messaggiReferentiSelAziende from './components/messaggiReferentiSelAziende.vue'
import messaggiReferentiInvio from './components/messaggiReferentiInvio.vue'
import messagesHistoryReferences from './components/messagesHistoryReferences.vue'
import editMessagesHistoryReferences from './components/editMessagesHistoryReferences.vue'
import initDocuments from './components/initDocuments.vue'
import messaggioBaseBack from './components/messaggioBaseBack.vue'
import messaggiReferentiSelReferenti from './components/messaggiReferentiSelReferenti.vue'
import initMessaggiReferenti from './components/initMessaggiReferenti.vue'
import vediCompanyAgila from './components/vediCompanyAgila.vue'
import initCompanyAgila from './components/initCompanyAgila.vue'
import initCompanyMessaggiReferenti from './components/initCompanyMessaggiReferenti.vue'
import companyMessaggiReferenti from './components/companyMessaggiReferenti.vue'
import reservateMsgUser from './components/reservateMsgUser.vue'
import documentsShared from './components/documentsShared.vue'
import editDocumentShared from './components/editDocumentShared.vue'
import filterUtentiSendDocCond from './components/filterUtentiSendDocCond.vue'
import utentiSendDocCond from './components/utentiSendDocCond.vue'
import utentiSendDocCondInvio from './components/utentiSendDocCondInvio.vue'
import initMessages from './components/initMessages.vue'
import historyMessaggioBase from './components/historyMessaggioBase.vue'
import initHistoryMessaggioBase from './components/initHistoryMessaggioBase.vue'
import vediAllegato from './components/vediAllegato.vue'
import testPython from './components/testPython.vue'
import initDash from './components/initDash.vue'
import dashEmployee from './components/dashEmployee.vue'
import vediDocumento from './components/vediDocumento.vue'
import testSignedDocument from './components/testSignedDocument.vue'
import sendMessageTitleMessage from './components/sendMessageTitleMessage.vue'
import filterSelectUsers from './components/filterSelectUsers.vue'
import selectUsers from './components/selectUsers.vue'
import sendMessageSetSigns from './components/sendMessageSetSigns.vue'
import editDocumentSharedSetSigns from './components/editDocumentSharedSetSigns.vue'
import verifyEmailAndMobile from './components/verifyEmailAndMobile.vue'
import verifiyMobile from './components/verifiyMobile.vue'

import initReservateMsgUser from './components/initReservateMsgUser.vue'



Vue.use(Router)

const router = new Router({
    routes: [
        {
            path: '/',
            name: 'home',
            component: Login
        },

        {
            path: '/dash',
            name: 'dash',
            component: dash
        },


        {
            path: '/timesheet',
            name: 'timesheet',
            component: timesheet
        },

        {
            path: '/category/edit-timesheet',
            name: 'timesheet',
            component: timesheet
        },

        {
            path: '/profile',
            name: 'profile',
            component: profile
        },



        {
            path: '/register',
            name: 'register',
            component: register
        },

        {
            path: '/category/modfiy-account',
            name: 'profile',
            component: profile
        }
        ,
        {
            path: '/category/timesheet',
            name: 'timesheet',
            component: timesheet
        }
        ,
        {
            path: '/info',
            name: 'info',
            component: info
        }

        ,
        {
            path: '/changepassword',
            name: 'changepassword',
            component: changepassword
        }

        ,
        {
            path: '/messages',
            name: 'messages',
            component: messages
        }


        ,
        {
            path: '/holiday',
            name: 'holiday',
            component: holiday
        }
        ,
        {
            path: '/disease',
            name: 'disease',
            component: disease
        }

        ,
        {
            path: '/filterusers',
            name: 'filterusers',
            component: filterusers
        }


        ,
        {
            path: '/users',
            name: 'users',
            component: users
        }

        ,
        {
            path: '/editUser/:id',
            name: 'editUser',
            component: editUser,
            props: true
        }

        ,

        {
            path: '/changepassword/:id',
            name: 'changepassword',
            component: changepasswordadmin,
            props: true
        },



        {
            path: '/settings',
            name: 'settings',
            component: settings
        },


        {
            path: '/changepasswordinitial',
            name: 'changepasswordinitial',
            component: changepasswordinitial
        }

        ,
        {
            path: '/reservate/:id',
            name: 'reservate',
            component: reservate,
            props: true
        }

        ,
        {
            path: '/firma',
            name: 'firma',
            component: firma
        }

        ,
        {
            path: '/sendMessage',
            name: 'sendMessage',
            component: sendMessage
        }

        ,
        {
            path: '/filterResources',
            name: 'filterResources',
            component: filterResources
        }

        ,
        {
            path: '/resources',
            name: 'resources',
            component: resources
        }

        ,
        {
            path: '/messaggioBase',
            name: 'messaggioBase',
            component: messaggioBase
        }

        ,
        {
            path: '/company',
            name: 'company',
            component: company
        }


        ,
        {
            path: '/editCompany/:id',
            name: 'editCompany',
            component: editCompany,
            props: true
        }

        ,
        {
            path: '/companyAgila',
            name: 'companyAgila',
            component: companyAgila
        }

        ,
        {
            path: '/editCompanyAgila/:id',
            name: 'editCompanyAgila',
            component: editCompanyAgila,
            props: true
        }

        ,
        {
            path: '/importResult',
            name: 'importResult',
            component: importResult,
            props: true
        }

        ,
        {
            path: '/filterReferenti',
            name: 'filterReferenti',
            component: filterReferenti,
            props: true
        }

        ,
        {
            path: '/referenti',
            name: 'referenti',
            component: referenti,
            props: true
        }

        ,
        {
            path: '/editReferente/:id',
            name: 'editReferente',
            component: editReferente,
            props: true
        },

        {
            path: '/copyReferente/:id',
            name: 'copyReferente',
            component: copyReferente,
            props:true
        },
        
        {
            path: '/logoFirma',
            name: 'logoFirma',
            component: logoFirma,
            props: true
        },

        {
            path: '/messaggiReferentiSelAziende',
            name: 'messaggiReferentiSelAziende',
            component: messaggiReferentiSelAziende,
            props: true
        },

        {
            path: '/messaggiReferentiInvio',
            name: 'messaggiReferentiInvio',
            component: messaggiReferentiInvio,
            props: true
        },

        {
            path: '/messagesHistoryReferences',
            name: 'messagesHistoryReferences',
            component: messagesHistoryReferences,
            props: true
        },




        {
            path: '/editMessagesHistoryReferences/:id',
            name: 'editMessagesHistoryReferences',
            component: editMessagesHistoryReferences,
            props:true
        },

        
        {
            path: '/initDocuments',
            name: 'initDocuments',
            component: initDocuments,
            props: true
        },

        {
            path: '/messaggioBaseBack',
            name: 'messaggioBaseBack',
            component: messaggioBaseBack
        },

        {
            path: '/messaggiReferentiSelReferenti',
            name: 'messaggiReferentiSelReferenti',
            component: messaggiReferentiSelReferenti
        },
        
        {
            path: '/initMessaggiReferenti',
            name: 'initMessaggiReferenti',
            component: initMessaggiReferenti
        },

        {
            path: '/vediCompanyAgila/:id',
            name: 'vediCompanyAgila',
            component: vediCompanyAgila,
            props: true
        },

        {
            path: '/initCompanyAgila',
            name: 'initCompanyAgila',
            component: initCompanyAgila
        },

        {
            path: '/initCompanyMessaggiReferenti',
            name: 'initCompanyMessaggiReferenti',
            component: initCompanyMessaggiReferenti
        },

        {
            path: '/companyMessaggiReferenti',
            name: 'companyMessaggiReferenti',
            component: companyMessaggiReferenti
        },

        {
            path: '/reservateMsgUser/:id',
            name: 'reservateMsgUser',
            component: reservateMsgUser,
            props: true
        },

        {
            path: '/documentsShared',
            name: 'documentsShared',
            component: documentsShared
        },

        {
            path: '/editDocumentShared/:id',
            name: 'editDocumentShared',
            component: editDocumentShared,
            props: true
        },

        {
            path: '/filterUtentiSendDocCond',
            name: 'filterUtentiSendDocCond',
            component: filterUtentiSendDocCond
        },

        {
            path: '/utentiSendDocCond',
            name: 'utentiSendDocCond',
            component: utentiSendDocCond
        },

        {
            path: '/utentiSendDocCondInvio',
            name: 'utentiSendDocCondInvio',
            component: utentiSendDocCondInvio
        },

        {
            path: '/initMessages',
            name: 'initMessages',
            component: initMessages
        },

        {
            path: '/historyMessaggioBase',
            name: 'historyMessaggioBase',
            component: historyMessaggioBase
        },

        {
            path: '/initHistoryMessaggioBase',
            name: 'initHistoryMessaggioBase',
            component: initHistoryMessaggioBase
        },


        {
            path: '/vediAllegato',
            name: 'vediAllegato',
            component: vediAllegato
        },

        {
            path: '/testPython',
            name: 'testPython',
            component: testPython
        },
        
        {
            path: '/initDash',
            name: 'initDash',
            component: initDash
        },
        
        {
            path: '/dashEmployee',
            name: 'dashEmployee',
            component: dashEmployee
        },

        {
            path: '/vediDocumento',
            name: 'vediDocumento',
            component: vediDocumento
        },

        {
            path: '/testSignedDocument',
            name: 'testSignedDocument',
            component: testSignedDocument
        },

        {
            path: '/sendMessageTitleMessage',
            name: 'sendMessageTitleMessage',
            component: sendMessageTitleMessage
        },
        
        {
            path: '/filterSelectUsers',
            name: 'filterSelectUsers',
            component: filterSelectUsers
        },
        
        {
            path: '/selectUsers',
            name: 'selectUsers',
            component: selectUsers
        },

        {
            path: '/sendMessageSetSigns',
            name: 'sendMessageSetSigns',
            component: sendMessageSetSigns
        },
        
        {
            path: '/editDocumentSharedSetSigns',
            name: 'editDocumentSharedSetSigns',
            component: editDocumentSharedSetSigns
        },



        {
            path: '/verifyEmailAndMobile',
            name: 'verifyEmailAndMobile',
            component: verifyEmailAndMobile
        },


        {
            path: '/verifiyMobile',
            name: 'verifiyMobile',
            component: verifiyMobile
        },

        {
            path: '/initReservateMsgUser/:id',
            name: 'initReservateMsgUser',
            component: initReservateMsgUser
        },

        
        

    ]
})


router.afterEach((to, from) => {
    console.log("from", from);
    console.log("going to " + to.fullPath)

})


export default router
